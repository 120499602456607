<template>
  <section v-if='hasFilteredIpoResults' class='overflow-x-scroll py-8'>
    <button class='flex flex-row justify-start gap-x-1 items-center py-2' @click='toggleDetails'>
      <component :is='toggleIcon' class='h-4' />
      <h4>{{accountType}} 참여자산정보</h4>
    </button>
    <table v-if='showDetails' class='mt-2 ml-6 text-sm table-fixed'>
      <thead>
        <tr class='border'>
          <th class='border-l'></th>
          <th class='border-l text-right'>참여계좌수</th>
          <th class='border-l'>담당자</th>
          <th class='border-l'>사무실</th>
          <th class='border-l'>팩스</th>
          <th class='border-l'>핸드폰</th>
          <th class='border-l'>이메일</th>
          <th class='border-l'>사업자번호</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border'>
          <td class='border-l'>{{accountType}}</td>
          <td class='border-l text-right'>{{filteredIpoResultsCount}}</td>
          <td class='border-l'>{{personCharge}}</td>
          <td class='border-l'>{{officePhoneNumber}}</td>
          <td class='border-l'>02-534-3305</td>
          <td class='border-l'>{{mobilePhoneNumber}}</td>
          <td class='border-l'>aistaff@timefolio.co.kr</td>
          <td class='border-l'>214-87-90612</td>
        </tr>
      </tbody>
    </table>
    <table v-if='showDetails' class='text-sm table-fixed'>
      <thead>
        <tr>
          <th rowspan='2'>기준일자</th>
          <th rowspan='2'>펀드코드</th>
          <th rowspan='2'>펀드명</th>
          <th rowspan='2'>펀드약칭</th>
          <th rowspan='2'>투자구분</th>
          <th rowspan='2'>설정액</th>
          <th rowspan='2'>순자산총액</th>
          <th rowspan='2'>자산총액</th>
          <th colspan='3'>3개월 평잔값 자산정보</th>
        </tr>
        <tr>
          <th>자산총액평잔</th>
          <th>순자산평잔</th>
          <th>원본액평잔</th>
        </tr>
      </thead>
      <tbody>
        <tr class='text-base text-blue-700 font-medium bg-yellow-200'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>합계(원)</td>
          <td class='text-right'>{{ numberStyle(totalOriginalAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalNavAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalTotalAssetsAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalTotalAssets3mAvgAmount.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalNav3mAvgAmount.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalOriginal3mAvgAmount.toFixed(0)) }}</td>
        </tr>
        <tr class='bg-yellow-200'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>합계(억원)</td>
          <td class='text-right'>{{ ukAmount(totalOriginalAmount) }} 억원</td>
          <td class='text-right'>{{ ukAmount(totalNavAmount) }} 억원</td>
          <td class='text-right'>{{ ukAmount(totalTotalAssetsAmount) }} 억원</td>
          <td class='text-right'>{{ ukAmount(totalTotalAssets3mAvgAmount) }} 억원</td>
          <td class='text-right'>{{ ukAmount(totalNav3mAvgAmount) }} 억원</td>
          <td class='text-right'>{{ ukAmount(totalOriginal3mAvgAmount) }} 억원</td>
        </tr>
        <tr v-if='hasPublicOfferingFund' class='bg-yellow-200'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>공모펀드 합계액(원)</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsOriginalAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsNavAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsTotalAssetsAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsTotalAssetsAmount3mAvg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsNavAmount3mAvg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalPublicOfferingFundsOriginalAmount3mAvg.toFixed(0)) }}</td>
        </tr>
        <tr v-if='hasPrivateEquityFund' class='bg-yellow-200'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>사모펀드 합계액(원)</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsOriginalAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsNavAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsTotalAssetsAmount) }}</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsTotalAssetsAmount3mAvg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsNavAmount3mAvg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(totalPrivateEquityFundsOriginalAmount3mAvg.toFixed(0)) }}</td>
        </tr>
        <tr v-for='result in filteredIpoResults' :key='`${result.account_type}-${result.id}`'>
          <td>{{ subscriptionDate(result) }}</td>
          <td>{{ result.fund_code }}</td>
          <td>{{ result.fund_name }}</td>
          <td>{{ result.fund_short_name }}</td>
          <td>{{ result.account_type }}</td>
          <td class='text-right'>{{ numberStyle(result.original_amount) }}</td>
          <td class='text-right'>{{ numberStyle(result.nav_amount) }}</td>
          <td class='text-right'>{{ numberStyle(result.total_assets_amount) }}</td>
          <td class='text-right'>{{ numberStyle(result.total_assets_amount_3m_avg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(result.nav_amount_3m_avg.toFixed(0)) }}</td>
          <td class='text-right'>{{ numberStyle(result.original_amount_3m_avg.toFixed(0)) }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoAccountTypeReports',
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
  },
  props: [
    'accountType',
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredIpoResults () {
      return this.ipoResults.filter(result => result.account_type === this.accountType)
    },
    publicOfferingFunds () {
      return this.filteredIpoResults.filter(result => result.is_public_offering === true)
    },
    privateEquityFunds () {
      return this.filteredIpoResults.filter(result => result.is_public_offering === false)
    },
    filteredIpoResultsCount () {
      return this.filteredIpoResults.length
    },
    hasFilteredIpoResults () {
      return this.filteredIpoResults.length > 0
    },
    hasPublicOfferingFund () {
      return this.publicOfferingFunds.length > 0
    },
    hasPrivateEquityFund () {
      return this.privateEquityFunds.length > 0
    },
    totalOriginalAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.original_amount, 0))
    },
    totalNavAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.nav_amount, 0))
    },
    totalTotalAssetsAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.total_assets_amount, 0))
    },
    totalPublicOfferingFundsOriginalAmount () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.original_amount, 0))
    },
    totalPublicOfferingFundsNavAmount () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.nav_amount, 0))
    },
    totalPublicOfferingFundsTotalAssetsAmount () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.total_assets_amount, 0))
    },
    totalPrivateEquityFundsOriginalAmount () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.original_amount, 0))
    },
    totalPrivateEquityFundsNavAmount () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.nav_amount, 0))
    },
    totalPrivateEquityFundsTotalAssetsAmount () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.total_assets_amount, 0))
    },
    totalOriginal3mAvgAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.original_amount_3m_avg, 0))
    },
    totalNav3mAvgAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.nav_amount_3m_avg, 0))
    },
    totalTotalAssets3mAvgAmount () {
      return Number(this.filteredIpoResults.reduce((accumulator, result) => accumulator + result.total_assets_amount_3m_avg, 0))
    },
    totalPublicOfferingFundsOriginalAmount3mAvg () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.original_amount_3m_avg, 0))
    },
    totalPublicOfferingFundsNavAmount3mAvg () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.nav_amount_3m_avg, 0))
    },
    totalPublicOfferingFundsTotalAssetsAmount3mAvg () {
      return Number(this.publicOfferingFunds.reduce((accumulator, result) => accumulator + result.total_assets_amount_3m_avg, 0))
    },
    totalPrivateEquityFundsOriginalAmount3mAvg () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.original_amount_3m_avg, 0))
    },
    totalPrivateEquityFundsNavAmount3mAvg () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.nav_amount_3m_avg, 0))
    },
    totalPrivateEquityFundsTotalAssetsAmount3mAvg () {
      return Number(this.privateEquityFunds.reduce((accumulator, result) => accumulator + result.total_assets_amount_3m_avg, 0))
    },
    personCharge () {
      return this.accountType === '집합' ? '박현우 과장' : '이원석 이사'
    },
    officePhoneNumber () {
      return this.accountType === '집합' ? '02-533-8941' : '02-522-8932'
    },
    mobilePhoneNumber () {
      return this.accountType === '집합' ? '010-8210-2842' : '010-3940-3460'
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    ukAmount (number) {
      return this.numberStyle((number / 100000000).toFixed(0))
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    subscriptionDate (result) {
      return result.ipo_summary ? dayjs(result.ipo_summary.predicted_date).subtract(1, 'day').format('YYYY-MM-DD') : ''
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
  td {
    @apply uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
</style>
